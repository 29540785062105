import React from 'react';
import { graphql } from 'gatsby';
import { TRANSPARENT, SINGLE_COL } from '@constants';
import {
  Layout, BasicDarkHero, ContentLayout,
} from '@molecules';
import { NewsItem, NewsPagination } from '@organisms';
import { setSearchParams, getKeyValue } from '@utils';

const ContentNewsListingTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;
  const {
    locale, newsItems, newsItemPageCount, newsItemPage, slug,
  } = pageContext;
  const { siteTitle } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent, disableAlertBar,
    enLanguagePageLinks, pageInOtherLanguages, disableDonationBar,
  } = page || {};
  const {
    title: pageTitle,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const searchParams = setSearchParams(location);

  return (
    <Layout
      location={location}
      metaDescription={metaDescription && metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme={TRANSPARENT}
      settings={settings}
      navAnimate
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableDonationBar={disableDonationBar}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
    >
      <BasicDarkHero
        title={pageTitle}
      />
      <ContentLayout layout={`${SINGLE_COL}noimage`}>
        {newsItems && (
          <ul>
            {newsItems.map((news) => {
              const { path: newsLink, pageContent: info, id: itemKey } = news || {};
              const {
                title, titleEyebrow, publicationDate, summary, publisher, category,
              } = info;
              return (
                <NewsItem
                  itemKey={itemKey}
                  title={titleEyebrow ? `${titleEyebrow} ${title}` : title}
                  category={category}
                  newsLink={newsLink}
                  summary={summary}
                  publisher={publisher}
                  publicationDate={publicationDate}
                />
              );
            })}
          </ul>
        )}
        {newsItemPageCount > 1 && (
          <NewsPagination
            slug={slug}
            itemPageCount={newsItemPageCount}
            itemPage={newsItemPage}
          />
        )}
      </ContentLayout>
    </Layout>
  );
};

export default ContentNewsListingTemplate;

export const contentNewsListingPageQuery = graphql`
  query ContentNewsListingPageBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentContentListing {
          title
          id
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
